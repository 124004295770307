/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require( "@fontsource/titillium-web")
require( "@fontsource/roboto")

// You can delete this file if you're not using it
exports.onRouteUpdate = ({ location }) => {

}